import { Component, Input } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
@Component({
  selector: 'app-custom-loading',
  standalone: true,
  imports: [ProgressSpinnerModule],
  templateUrl: './custom-loading.component.html',
  styleUrl: './custom-loading.component.scss'
})
export class CustomLoadingComponent {
  @Input() styleClass = '';
  @Input() isSmall =false
  
}
